// Angular modules
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import { Subject } from 'rxjs';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Base class
import { BaseComponent } from './base';
import { EventBusService } from '@services/event-bus.service';

@Component({
  template: '',
})
export class BaseQuickviewComponent<T> extends BaseComponent implements OnInit, OnDestroy {

  protected formGroup!: FormGroup;

  protected entity!: any;

  protected routeToEdit = '';

  protected ngUnsubscribe = new Subject<boolean>();

  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init & Destroy ---------------------------------------------
  // -------------------------------------------------------------------------------

  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected router: Router,
    protected override logger: NGXLogger,
    protected translateService: TranslateService,
    protected activeModal: NgbActiveModal,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
  ) {
    super(location, titleService, storeService, logger, activatedRoute, eventBusService);
  }

  override ngOnInit(): void {

    super.ngOnInit();

    this.logger.info('ngOnInit from BaseQuickviewComponent');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }


  // -------------------------------------------------------------------------------
  // NOTE Form Init ----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  /**
   * Resets form and init FormControl values with entity object assigning default values
   */
  protected setDefaultValuesToFormGroup(): void {
    this.formGroup.reset(this.entity);
  }


  gotoEditPage($event: any) {



    this.router.navigate([`${this.routeToEdit}${this.entity.id}`]);
    this.activeModal.close();

  }
}
